const navigationOptions = {
  logo: "img/logos/white-logo.png",
  navs: [
    {
      href: "#about",
      text: "About"
    },
    {
      href: "#events",
      text: "Events"
    },
    {
      href: "#impact",
      text: "Impact"
    },
    {
      href: "#partners",
      text: "Partners"
    },
    {
      href: "#team",
      text: "Team"
    },
    {
      href: "#contact",
      text: "Contact Us"
    }
  ]
}

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#ef-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className='navbar-left page-scroll' href='#page-top'>
            <img src={navigationOptions.logo} width="40%" alt="logo" />
          </a>
        </div>
        
        <div
          className="collapse navbar-collapse"
          id="ef-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            { navigationOptions.navs.map((d,i) => (
                <li key={`${d.text}-${i}`}>
                  <a href={d.href} className='page-scroll'>
                    {d.text}
                  </a>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </nav>
  );
};
