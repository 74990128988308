import { Carousel } from 'react-responsive-carousel';

const headerOptions = {
  images: [
    { url: "img/hero/img1.jpg" },
    { url: "img/hero/img2.jpg" },
    { url: "img/hero/img3.jpg" }
  ],
  title: "We exist to ensure an equitable education for all",
  paragraph: "We advise full stack solution to the students, schools, colleges, education regulators and Govts to bring a fundamental and holistic change in the education ecosystem."
};

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <Carousel
            interval={3000}
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
          >
              {headerOptions.images.map((d,i) => (
                <div key={`${d.name}-${i}`}>
                  <img src={d.url} alt="..." width="100%" style={{ opacity: 0.8 }}/>
                </div>
              ))}
          </Carousel>
          <div className='text-overlay'>
            <div className='text-subpart1'>{headerOptions.title}</div>
            <div className='text-subpart2'>{headerOptions.paragraph}</div>
          </div>
        </div>
      </div>
    </header>
  )
}
