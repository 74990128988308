import Carousel from "react-multi-carousel";
import { Card } from "./card";
import "react-multi-carousel/lib/styles.css";

/* 
  This component displays only 8 images from recent events.
  The image size must be 600x450 (width x height).
  This helps improve the page load speed by decreasing the page size
*/

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const eventOptions = {
  images: [
    {
      name: "event-1",
      image: "img/events/event-1.jpeg"
    },
    {
      name: "event-2",
      image: "img/events/event-2.jpeg"
    },
    {
      name: "event-3",
      image: "img/events/event-3.jpeg"
    },
    {
      name: "event-4",
      image: "img/events/event-4.jpeg"
    },
    {
      name: "event-5",
      image: "img/events/event-5.jpeg"
    },
    {
      name: "event-6",
      image: "img/events/event-6.jpeg"
    },
    {
      name: "event-7",
      image: "img/events/event-7.jpeg"
    },
    {
      name: "event-8",
      image: "img/events/event-8.jpeg"
    }
  ]
};

export const Events = (props) => {
  return (
    <div id="events" className="text-center">
      <div className="section-title">
        <h2>News & Events</h2>
      </div>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        slidesToSlide={1}
      >
        {eventOptions.images.map((d, i) => (
          <div
            key={`${d.name}-${i}`}
            style={{ padding: "0 20px" }}
          >
            {""}
            <Card
              cardInfo={d}
              imgStyles={{ width: "100%" }}
              cardStyles={{ minHeight: "450px" }}
            />
          </div>
          ))
        }
      </Carousel>
    </div>
  )
}
