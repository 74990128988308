const courseOptions = [
  {
    icon: "fa fa-sitemap",
    url: "https://www.netacad.com/courses/networking",
    name: "Networking",
    text: "Networks are becoming more intelligent, programmable and software-driven. Networking Academy courses support this evolution and expose learners to new concepts and hands-on experiences with courses tied to CCNA certifications."
  },
  {
    icon: "fa fa-key",
    url: "https://www.netacad.com/courses/cybersecurity",
    name: "Cybersecurity",
    text: "Cybersecurity professionals who can protect and defend an organization's network are in high-demand right now. Put your detective-like qualities to work by gaining knowledge toward a career in fighting cybercrime."
  },
  {
    icon: "fa fa-code",
    url: "https://www.netacad.com/courses/programming",
    name: "Programming",
    text: "Endless possibilities await for conceptualizing and creating software after you acquire the right programming skills. Each programming course prepares you for an industry recognized certification."
  },
  {
    icon: "fa fa-rss-square",
    url: "https://www.netacad.com/courses/iot",
    name: "IoT",
    text: "An extremely hot area of technology right now, with new companies, products and opportunities springing up all over the world to take advantage of it. Join the transformation and improve outcomes in any industry by connecting devices and analyzing data to find patterns."
  },
  {
    icon: "fa fa-cogs",
    url: "https://www.netacad.com/courses/os-it",
    name: "OS & IT",
    text: "Whether you're a novice to the digital world or an aspiring SysAdmin or programmer, these courses will propel you forward. Advanced courses will also prepare you for career-ready certification."
  },
  {
    icon: "fa fa-lightbulb-o",
    url: "https://www.netacad.com/courses/business/entrepreneurship",
    name: "Entrepreneurship",
    text: "The winding road between concept and execution requires business acumen, financial skills, and, most importantly, the right mind-set. Supplement your technical expertise with entrepreneurial thinking, business development, and financial management skills."
  }
];

export const Courses = (props) => {
  return (
    <div id='courses' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Popular Courses</h2>
          <p>
            We help you build world class careers in the following fields. <br />
            View all offered courses <a href="https://www.netacad.com/courses/all-courses" target="_blank" rel="noreferrer">HERE</a>.
          </p>
        </div>
        <div className='row'>
          { courseOptions.map((d, i) => (
            <div key={`${d.name}-${i}`} className='col-md-4'>
              {' '}
              <i className={d.icon}></i>
              <div>
                <h3>{d.name}</h3>
                <p className="text-justify">{d.text}</p>
                <a href={d.url} target="_blank" rel="noreferrer">LEARN MORE</a>
              </div>
              <br />
            </div>            
            ))
          }
        </div>
      </div>
    </div>
  )
}
