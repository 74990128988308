import { Courses } from './courses';
import { Features } from './features';
import { TheoryOfChange } from './theoryOfChange';
import { UserType } from './usertype';

const aboutOptions = {
  paragraph: "EdCreate Foundation exists to ensure an equitable education for all. We advise full stack solution to the students, schools, colleges, education regulators and Govts to bring a fundamental and holistic change in the education ecosystem.",
  aboutImg: "img/about.jpg",
  ciscoLogo: "img/company-logos/cisco.png"
};


export const About = (props) => {
  return (
    <div>
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src={aboutOptions.aboutImg} className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p>{aboutOptions.paragraph}</p>
                <h3>Supported By</h3>
                <div className='text-center'>
                  <img src={aboutOptions.ciscoLogo} style={{ width: "250px" }} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <div>
        <Courses />
        <Features />
        <TheoryOfChange />
        <UserType />
      </div>
    </div>
  );
};
