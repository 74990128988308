import { Card } from "./card";

const userTypeOptions = [
  {
    name: "student",
    title: "I am a student",
    description: "Enroll in the programs directly offered by IT giants",
    image: "img/usertype/student.jpeg"
  },
  {
    name: "educator",
    title: "I am an Educator",
    description: "Join the global educator community and get life-time free teaching credentials",
    image: "img/usertype/educator.jpeg"
  },
  {
    name: "employer",
    title: "I am an Employer",
    description: "Hire from our world class talent pool",
    image: "img/usertype/employer.jpeg"
  }
];

export const UserType = (props) => {
  function handleUser(e) {
    window.location.href = "#contact";
  }

  return (
    <div id="usertype" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Who are you ?</h2>
        </div>
        <div className="row">
          {userTypeOptions.map((d, i) => (
            <div
              key={`${d.name}-${i}`}
              className="col-md-4"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                handleUser(e);
              }}
            >
              <Card cardInfo={d} />
            </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
