import Carousel from "react-multi-carousel";
import { Card } from "./card";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const partnerOptions = [
  {
    image: "img/partners/logo1.png",
    name: "partner1"
  },
  {
    image: "img/partners/logo2.jpeg",
    name: "partner2"
  },
  {
    image: "img/partners/logo3.png",
    name: "partner3"
  },
  {
    image: "img/partners/logo4.png",
    name: "partner4"
  },
  {
    image: "img/partners/logo5.png",
    name: "partner5"
  },
  {
    image: "img/partners/logo6.png",
    name: "partner6"
  },
  {
    image: "img/partners/logo7.png",
    name: "partner7"
  },
  {
    image: "img/partners/logo8.png",
    name: "partner8"
  },
  {
    image: "img/partners/logo9.png",
    name: "partner9"
  },
  {
    image: "img/partners/logo10.png",
    name: "partner10"
  }
];

export const Partners = (props) => {
  return (
    <div id='partners' className='text-center'>
	    <div className='section-title'>
          <h2>Partners</h2>
      </div>
      <Carousel 
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        slidesToSlide={1}
      >
        {partnerOptions.map((d, i) => (
          <div
            key={`${d.name}-${i}`}
            style={{ padding: "0 20px" }}
          >
            <Card
              cardInfo={d}
              imgStyles={{ width: "40%" }}
              cardStyles={{ minHeight: "180px" }}
            />
          </div>
          ))
        }
      </Carousel>
    </div>
  )
}
