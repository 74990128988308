import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Partners } from "./components/partners";
import { Impact } from "./components/impact";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./App.css";
import { Events } from "./components/events";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {  
  return (
    <div>
      <Navigation />
      <Header />
      <About />
      <Events />
      <Impact />
      <Partners />
      <Team />
      <Contact />
    </div>
  );
};

export default App;
