import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card } from "./card";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const featureOptions = [
  {
    image: "img/capabilities/learning.jpeg",
    title: "Learning",
    text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  },
  {
    title: "Assessment",
    image: "img/capabilities/assessment.jpeg",
    text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  },
  {
    title: "Certification",
    image: "img/capabilities/certification.jpeg",
    text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  },
  {
    title: "Mentoring",
    image: "img/capabilities/mentoring.jpeg",
    text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  },
  {
    title: "Internships",
    image: "img/capabilities/internships.jpeg",
    text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  },
  {
    title: "Jobs",
    image: "img/capabilities/jobs.jpeg",
    text: "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
  }
];

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Capabilities</h2>
        </div>
        <div className="row">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            slidesToSlide={1}
          >
            {featureOptions.map((d, i) => (
              <div
                key={`${d.title}-${i}`}
                style={{ padding: "0 20px" }}
              >
                {""}
                <Card
                  cardInfo={d}
                  imgStyles={{ height: "150px" }}
                  cardStyles={{ minHeight: "180px" }}
                />
              </div>
              ))
            }
          </Carousel>
        </div>
      </div>
    </div>
  );
};
