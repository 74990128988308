export const Card = ({
  cardInfo,
  imgStyles = {},
  cardStyles = {},
}) => {
  return (
    <div className="certificate-card" style={cardStyles}>
      <div className="certificate-image-div">
        <img
          src={cardInfo.image}
          alt="PWA"
          className="card-image"
          style={imgStyles}
        ></img>
      </div>
      <div className="certificate-detail-div">
        {cardInfo.title
          ? <h5 className="card-title">{cardInfo.title}</h5>
          : "" 
        }
        {cardInfo.description 
          ? <p className="card-subtitle text-justify">{cardInfo.description}</p>
          : ""
        }
      </div>
      <div className="certificate-card-footer"></div>
    </div>
  );
};
