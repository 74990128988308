import Carousel from "react-multi-carousel";
import CountUp from 'react-countup';
import { Card } from "./card";
// import { Testimonials } from "./testimonials";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const impactOptions = {
  stats: [
    {
      name: "states",
      number: 15,
      startnum: 0,
      suffixText: " States"
    },
    {
      name: "colleges",
      number: 700,
      startnum: 100,
      suffixText: "+ Colleges"
    },
    {
      name: "educators",
      number: 2000,
      startnum: 1000,
      suffixText: "+ Educators"
    },
    {
      name: "youths",
      number: 200000,
      startnum: 100000,
      suffixText: "+ Youths"
    }
  ],
  companies: [
    {
      name: "capgemini",
      image: "img/company-logos/capgemini.png"
    },
    {
      name: "cognizant",
      image: "img/company-logos/cognizant.png"
    },
    {
      name: "hcl",
      image: "img/company-logos/hcl.png"
    },
    {
      name: "infosys",
      image: "img/company-logos/infosys.png"
    },
    {
      name: "tcs",
      image: "img/company-logos/tcs.png"
    },
    {
      title: "tech-mahindra",
      image: "img/company-logos/tech-mahindra.png"
    },
    {
      title: "wipro",
      image: "img/company-logos/wipro.jpg"
    }
  ]
};

export const Impact = (props) => {
  return (
    <div>
      <div id='impact' className='text-center'>
        <div className="container-fluid">
          <div className='section-title'>
              <h2>Impact</h2>
          </div>
          <div className="row">
            {impactOptions.stats.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-3'>
                <h1>
                  <CountUp
                    duration={3}
                    separator=","
                    start={d.startnum}
                    end={d.number}
                    suffix={d.suffixText}
                  />
                </h1>
              </div>
              ))
            }
          </div>
        </div>
        <div className="companies">
          <div className='section-title'>
              <h2>Our Students work at</h2>
          </div>
          <Carousel 
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            slidesToSlide={1}
          >
            {impactOptions.companies.map((d, i) => (
              <div
                key={`${d.name}-${i}`}
                style={{ padding: "0 20px" }}
              >
                <Card
                  cardInfo={d}
                  cardStyles={{ minHeight: "180px" }}
                />
              </div>
              ))
            }
          </Carousel>
        </div>
      </div>
      {/* <Testimonials /> */}
    </div>
  )
}
