const theoryOfChangeOptions = [
  {
    icon: "fa fa-globe",
    name: "Global Platform",
    text: "Leading edge content,personalized learning, exclusive LMS licenses, assessment and certification. Everything at one place."
  },
  {
    icon: "fa fa-graduation-cap",
    name: "Blended Learning",
    text: "In-person class by faculties, online learning content and access of virtual machines and simulators. We are making learning seamless for all."
  },
  {
    icon: "fa fa-briefcase",
    name: "Skills to jobs",
    text: "We focus on those skill which are in demand globally and we nurture the students as per the industry requirements."
  }
];

export const TheoryOfChange = (props) => {
  return (
    <div id="theoryOfChange" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Theory of Change</h2>
        </div>
        <div className="row">
          {theoryOfChangeOptions.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4">
              {" "}
              <i className={d.icon}></i>
              <div className="desc">
                <h3>{d.name}</h3>
                <p className="text-justify">{d.text}</p>
              </div>
            </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
