const teamOptions = [
  {
    image: "img/team/ram-gowtam.jpg",
    name: "Ram Gowtam",
    job: "Head - Partnerships"
  },
  {
    image: "img/team/naveen-kumar.jpg",
    name: "Naveen Kumar",
    job: "Head - Strategic Initiatives"
  },
  {
    image: "img/team/priyaranjan-sinha.jpg",
    name: "Priyaranjan Sinha",
    job: "Head - Operations"
  }
];

export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
          <p className="text-justify">
            We are founded and mentored by a group of IIT alumni who believed in making revolutionary 
            and pedagogical changes in the education system. Traditional education system has made itself 
            inaccessible for millions of learners due to the digital divide. We advocate to harness the 
            use of technology for the easy, seamless and equitable education for all. 
          </p>
        </div>
        <div id='row'>
          {teamOptions.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4 col-sm-6 team'>
                <div className='thumbnail'>
                  {' '}
                  <img src={d.image} alt='...' className='team-img' />
                  <div className='caption'>
                    <h4>{d.name}</h4>
                    <p>{d.job}</p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
